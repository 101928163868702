import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { AppHeader, AppHeaderProps } from '@tuunetech/tuune-components';
import { HEADER_LINKS } from 'staticData/Header';
import { Layout, LinkField } from 'types';
import { getUrl } from 'utils/helpers';

export interface HeaderProps {
  barColor?: AppHeaderProps['color'];
  links?: { url: LinkField; text: string }[];
}

export const Header: React.FC<HeaderProps> = ({
  barColor = 'light',
  links,
}) => {
  const layoutStaticData = useStaticQuery<Layout>(
    graphql`
      query {
        prismicLayout {
          _previewable
          data {
            links {
              text
              url {
                url
              }
            }
          }
        }
      }
    `,
  );
  const { data: layout } = useMergePrismicPreviewData(layoutStaticData);

  const buttons = useMemo(() => {
    let linksSet = layout.prismicLayout.data.links;
    if (!!links && links.length) {
      linksSet = links;
    }

    // edge case when we add in Prismic new link but not fill it
    // In this case we show empty header, only logo and no links
    // Adding a new empty link is also a default Prismic behaviour, so this condition also protects header from ghost button
    if (links?.length === 1 && (!links[0].url || !links[0].text)) {
      linksSet = [];
    }

    return linksSet.map(({ text, url }) => ({
      title: text,
      // when all pages are hosted in prismic there is no need to manually replace external links with internal
      onClick: () => navigate(getUrl(url.url || '')),
    }));
  }, [links, layout.prismicLayout.data.links]);

  return (
    <AppHeader
      handleStartAdornmentClick={() => navigate(HEADER_LINKS.home)}
      handlePageTitleClick={() => navigate(HEADER_LINKS.home)}
      color={barColor}
      colorOnScroll="inherit"
      buttons={buttons}
      position="sticky"
    />
  );
};
