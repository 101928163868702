export const HEADER_LINKS = {
  home: '/',
  waitlist: '/early-access',
  notFound: '/404',
  privacyPolicyUk: '/privacy-policy-uk',
  privacyPolicy: '/privacy-policy',
  privacyPolicyApp: '/privacy-policy-app',
  cookiePolicy: '/cookie-policy',
  terms: '/terms-and-conditions',
  termsUk: '/terms-and-conditions-uk',
  informedConsent: '/informed-consent',
  hipaaConsent: '/hipaa-authorization-to-use-and-disclose-phi',
  contactUs: '/contact',
  blog: process.env.GATSBY_BLOG_DOMAIN
    ? `https://${process.env.GATSBY_BLOG_DOMAIN}`
    : '/',
};
