import { HEADER_LINKS } from '../staticData/Header';

export const waitForGlobal = (key: string, cb: () => void): void => {
  if (window[key]) {
    cb();
  } else {
    setTimeout(() => {
      waitForGlobal(key, cb);
    }, 100);
  }
};

export const getUrl = (url: string): string => {
  if (url.includes(HEADER_LINKS.contactUs)) {
    return HEADER_LINKS.contactUs;
  }
  if (url.includes(HEADER_LINKS.cookiePolicy)) {
    return HEADER_LINKS.cookiePolicy;
  }
  if (url.includes(HEADER_LINKS.privacyPolicy)) {
    return HEADER_LINKS.privacyPolicy;
  }
  if (url.includes(HEADER_LINKS.terms)) {
    return HEADER_LINKS.terms;
  }

  const domain = 'https://tuune.com';
  if (url.startsWith(domain)) {
    if (url.startsWith(domain + '#')) {
      return url.replace(domain, '/');
    }
    return url.replace(domain, '');
  }

  return url;
};
