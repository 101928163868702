import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Main = styled.div`
  position: relative;
  top: -64px;
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -64px;
`;

export const PromoBox = styled.div<{
  $bg: string | null;
  $color: string | null;
}>`
  width: 100%;
  padding: 16px;
  background-color: ${({ $bg, theme }) =>
    $bg || theme.palette.common.purple[900]};
  color: ${({ $color, theme }) => $color || theme.palette.common.white};
  display: flex;
  justify-content: center;
`;
