import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { PrismicField, SEOData } from 'types';
import { HEADER_LINKS } from 'staticData/Header';
import {
  Footer,
  SeoProps,
  SEO,
  AppHeaderProps,
} from '@tuunetech/tuune-components';
import { Header, HeaderProps } from '../Header';
import { Link } from '../Link';
import { LayoutWrapper, PromoBox, Main } from './styles';
import { commonHtmlSerializer } from '../../slices/commonHtmlSerializer';

interface LayoutProps {
  path?: string;
  seoData?: SeoProps;
  barColor?: AppHeaderProps['color'];
  links?: HeaderProps['links'];
  promo: {
    content: PrismicField;
    bg: string | null;
    color: string | null;
  } | null;
}

const LINKS = [
  {
    sectionTitle: 'about',
    links: [
      {
        name: 'About',
        link: '/about-us',
        target: '_self',
      },
      {
        name: 'Blog',
        link: process.env.GATSBY_BLOG_DOMAIN
          ? `https://${process.env.GATSBY_BLOG_DOMAIN}`
          : '/',
        target: '_self',
      },
      {
        name: 'Birth Control',
        link: '/birth-control',
        target: '_self',
      },
      {
        name: 'Hormone Testing',
        link: '/hormone-test',
        target: '_self',
      },
      {
        name: 'Hormonal Wellbeing +',
        link: '/hormonal-wellbeing',
        target: '_self',
      },
      {
        name: 'Contact us',
        link: '/contact',
        target: '_self',
      },
    ],
  },
  {
    sectionTitle: 'connect',
    links: [
      {
        name: 'Instagram',
        link: 'https://www.instagram.com/tuunehealth/',
        target: '_blank',
      },
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/tuunehealth/',
        target: '_blank',
      },
      {
        name: 'Twitter',
        link: 'https://twitter.com/Tuunehealth/',
        target: '_blank',
      },
    ],
  },
];

const POLICY_LINKS = [
  { name: 'Privacy Policy', link: HEADER_LINKS.privacyPolicy },
  {
    name: 'Cookie Policy',
    link: HEADER_LINKS.cookiePolicy,
  },
  { name: 'Terms of Service', link: HEADER_LINKS.terms },
];

const Form = styled.div`
  button {
    margin-left: 24px;
    padding: 11px 31px !important;
  }

  input {
    border-radius: 10px !important;
  }

  div[data-testid='form-row'] {
    > div {
      min-height: fit-content;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    div[data-testid='form-row'] {
      flex-direction: column !important;
    }
    button {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const Layout: React.FC<LayoutProps> = ({
  children,
  path,
  seoData,
  barColor,
  links,
  promo,
}) => {
  const { SEO: defaultSeoData } = useStaticQuery<SEOData>(graphql`
    query {
      SEO: site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
          twitterUsername
        }
      }
    }
  `);

  const seo = useMemo(() => {
    const {
      defaultTitle,
      defaultDescription,
      siteUrl,
      twitterUsername,
    } = defaultSeoData.siteMetadata;

    return {
      isArticle: seoData?.isArticle,
      title: seoData?.title || defaultTitle,
      description: seoData?.description || defaultDescription,
      keywords: seoData?.keywords,
      image: seoData?.image,
      url: seoData?.url || `${siteUrl}${path}`,
      lang: seoData?.lang || 'en',
      twitterUsername: seoData?.twitterUsername || twitterUsername,
      additional: seoData?.additional,
    };
  }, [defaultSeoData, seoData, path]);

  const klaviyoFormId = process.env.GATSBY_KLAVIYO_ID || 'R6ETPs';

  return (
    <React.Fragment>
      {promo && (
        <PromoBox $bg={promo.bg} $color={promo.color}>
          <RichText
            render={promo.content.raw}
            htmlSerializer={commonHtmlSerializer}
          />
        </PromoBox>
      )}
      <LayoutWrapper>
        <SEO {...seo} />
        <Header barColor={barColor} links={links} />
        <Main>{children}</Main>
        <Footer
          linkRenderer={({ name, target, link }) => (
            <Link
              key={name}
              to={typeof link === 'string' ? link : ''}
              onClick={typeof link === 'function' ? link : undefined}
              target={target}
              underline="hover"
              display="inline"
              variant="caption"
              trackingData={{
                event: 'cta',
                cta_position: 'footer',
                cta_text: name,
              }}
            >
              {name}
            </Link>
          )}
          links={{ main: LINKS, policy: POLICY_LINKS }}
          signupForm={
            <Form>
              <div className={`klaviyo-form-${klaviyoFormId}`} />
            </Form>
          }
        />
      </LayoutWrapper>
    </React.Fragment>
  );
};
