import React, { ReactNode } from 'react';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import {
  BODY1,
  BODY2,
  ContentBlock,
  H1,
  H2,
  H3,
  H4,
  H5,
} from '@tuunetech/tuune-components';
import { Check } from '@material-ui/icons';
import { getUrl } from 'utils/helpers';
import { Link } from '../shared/Link';

const commonSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
      return <BODY1 key={key}>{children}</BODY1>;
    case Elements.heading1:
      return <H1 key={key}>{children}</H1>;
    case Elements.heading2:
      return <H2 key={key}>{children}</H2>;
    case Elements.heading3:
      return <H3 key={key}>{children}</H3>;
    case Elements.heading4:
      return <H4 key={key}>{children}</H4>;
    case Elements.heading5:
      return <H5 key={key}>{children}</H5>;
    case Elements.list:
      return <div>{children}</div>;
    case Elements.hyperlink:
      return (
        <Link
          to={getUrl(element.data.url || '')}
          color="inherit"
          target={element.data.target}
        >
          {content}
        </Link>
      );
    case Elements.listItem:
      return (
        <ContentBlock
          startAdornment={<Check />}
          content={<BODY1 $marginBottom={8}>{children}</BODY1>}
        />
      );
    case Elements.image:
      return <img src={element.url} />;
  }
};

export const commonHtmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  if (type === Elements.paragraph) {
    return <BODY1 key={key}>{children}</BODY1>;
  }
  return commonSerializer(type, element, content, children, key);
};

export const commonHtmlSerializerSmallText: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  if (type === Elements.paragraph) {
    return <BODY2 key={key}>{children}</BODY2>;
  }
  return commonSerializer(type, element, content, children, key);
};
